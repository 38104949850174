import {Tensor, InferenceSession} from "onnxruntime-web";


function normalize(pose) {
  const num = 16;
  let xmin = 100;
  let xmax = -100;
  let ymin = 100;
  let ymax = -100;
  for (let i = 0; i < num; ++i) {
    xmin = Math.min(xmin, pose[2*i])
    xmax = Math.max(xmax, pose[2*i])
    ymin = Math.min(ymin, pose[2*i+1])
    ymax = Math.max(ymax, pose[2*i+1])
  }
  const xdiff = xmax-xmin;
  const ydiff = ymax-ymin;

  for (let i = 0; i<num; ++i) {
    pose[2*i] = 1 - ((pose[2*i] - xmin)/xdiff);
    pose[2*i+1] = (pose[2*i+1] - ymin)/ydiff;
  }
  return pose;
}

function distance(tensor1, tensor2) {
  let dist = 0;
  const num = 64;
  for (let i = 0; i < num; ++i) {
    dist += Math.pow(tensor2[i] - tensor1[i], 2);
  }
  return Math.sqrt(dist);
}

function dist2score(dist) {

}

export const Mpii2mpPoseIdMap = [28, 27, 26, 25, 24, 23, 16, 15, 14, 13, 12, 11, 2324, 1112, 1112, 708];
export const Mpii2MoveNetIdMap = [16, 15, 14, 13, 12, 11, 10, 9,  8,  7,  6,  5, 1112, 506, 506, 304];

export function convertPoseAndNormalize(landmarks, idMap) {
  const mpiiPose = [];
  console.log(landmarks)
  for (let i = 0; i < idMap.length; ++i) {
    let id = idMap[i];
    // console.log('id: ' + id);
    if (id > 100) {
      const id0 = Math.floor(id / 100);
      const id1 = id % 100;
      // console.log('id0: ' + id0);
      // console.log('id1: ' + id1);
      const x = (landmarks[id0].x + landmarks[id1].x) / 2;
      const y = (landmarks[id0].y + landmarks[id1].y) / 2;
      mpiiPose.push(x);
      mpiiPose.push(y);
    } else {
      mpiiPose.push(landmarks[id].x)
      mpiiPose.push(landmarks[id].y)
    }
  }
  return normalize(mpiiPose);
}

const test_input1 = {
  "input.1":
  new Tensor("float32", new Float32Array(normalize([
    4.44139391e-01, 6.93350911e-01, 5.15419722e-01, 7.01191783e-01,
    4.44139391e-01, 6.07101679e-01, 5.15419722e-01, 6.14942551e-01,
    4.44139391e-01, 5.13011575e-01, 5.15419722e-01, 5.13011575e-01,
    3.87115121e-01, 3.64035636e-01, 7.29260802e-01, 4.34603214e-01,
    3.30090851e-01, 4.03239846e-01, 6.29468322e-01, 4.34603214e-01,
    4.15627271e-01, 4.11080688e-01, 5.43931901e-01, 4.18921530e-01,
    4.72651541e-01, 5.13011575e-01, 4.86907631e-01, 4.11080688e-01,
    4.72651541e-01, 3.95399004e-01, 4.86907631e-01, 3.48353982e-01
  ])), [1,1,1,32])
}

const test_input2 = {
  "input.1":
  new Tensor("float32", new Float32Array(normalize([
    4.23205554e-01, 6.98905468e-01, 5.03083348e-01, 6.91583276e-01,
    4.23205554e-01, 6.03717804e-01, 5.03083348e-01, 6.03717804e-01,
    4.36518520e-01, 5.08530080e-01, 4.89770353e-01, 5.08530080e-01,
    4.09892589e-01, 3.69409651e-01, 6.62838876e-01, 4.42630947e-01,
    3.43327790e-01, 3.91376019e-01, 6.09587014e-01, 4.27986681e-01,
    3.96579623e-01, 4.20664549e-01, 5.29709220e-01, 4.27986681e-01,
    4.63144422e-01, 5.08530080e-01, 4.63144422e-01, 4.27986681e-01,
    4.63144422e-01, 4.13342416e-01, 4.76457387e-01, 3.62087518e-01
  ])), [1, 1,1,32]),
}

let session = null;

const loadModel = async () => {
  const url = "/static/siamese.onnx";
  session = await InferenceSession.create(
    url,
    {
      executionProviders: ["webgl"]
    }
  )
}

loadModel();

export const runSync = async (pose1, pose2) => {
const input1 = {
  "input.1":
  new Tensor("float32", new Float32Array(pose1), [1, 1,1,32]),
}
const input2 = {
  "input.1":
  new Tensor("float32", new Float32Array(pose2), [1, 1,1,32]),
}


  let output1 = (await session.run(input1))['17'].data;
  let output2 = (await session.run(input2))['17'].data;
  console.log(output1);
  console.log(output2);
  return distance(output1, output2); 
  
}