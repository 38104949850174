
// a class that store the human body keypoints and calculate the score
// the score is calculated based on the distance between the keypoints
// Index: 0, Keypoint Name: right_ankle
// Index: 1, Keypoint Name: left_ankle
// Index: 2, Keypoint Name: right_knee
// Index: 3, Keypoint Name: left_knee
// Index: 4, Keypoint Name: right_hip
// Index: 5, Keypoint Name: left_hip
// Index: 6, Keypoint Name: right_wrist
// Index: 7, Keypoint Name: left_wrist
// Index: 8, Keypoint Name: right_elbow
// Index: 9, Keypoint Name: left_elbow
// Index: 10, Keypoint Name: right_shoulder
// Index: 11, Keypoint Name: left_shoulder
// Index: 12, Keypoint Name: left_hip-right_hip
// Index: 13, Keypoint Name: left_shoulder-right_shoulder
// Index: 14, Keypoint Name: left_shoulder-right_shoulder
// Index: 15, Keypoint Name: left_ear-right_ear

class Score {
    constructor(setLWristSpeed,  setRWristSpeed, setLArmAngle, setRArmAngle, setLLegAngle, setRLegAngle, setActivityScore) {
        this.kpsList = [];
        this.setLWristSpeed = setLWristSpeed;
        this.setRWristSpeed = setRWristSpeed;
        this.setLArmAngle = setLArmAngle;
        this.setRArmAngle = setRArmAngle;
        this.setLLegAngle = setLLegAngle;
        this.setRLegAngle = setRLegAngle;
        this.setActivityScore = setActivityScore;
    }

    updateKps(kps) {
        this.kpsList.push(kps);
        // pop the first element if the length is greater than 10
        if (this.kpsList.length > 10) {
            this.kpsList.shift();
        }
        // console.log('length of kpsList: ' + this.kpsList.length);
    }
    
    // calculate the score based on the keypoints
    updateActivityScore() {
        // return random number for now
        return Math.random() * 100;
    }

    calculateAngle(p1, p2, p3) {
        // Vectors from p2 to p1 and p2 to p3
        const vectorP2P1 = {
            x: p1[0] - p2[0],
            y: p1[1] - p2[1]
        };
        
        const vectorP2P3 = {
            x: p3[0] - p2[0],
            y: p3[1] - p2[1]
        };
    
        // Dot product of the two vectors
        const dotProduct = (vectorP2P1.x * vectorP2P3.x) + (vectorP2P1.y * vectorP2P3.y);
    
        // Magnitude of the vectors
        const magnitudeP2P1 = Math.sqrt((vectorP2P1.x ** 2) + (vectorP2P1.y ** 2));
        const magnitudeP2P3 = Math.sqrt((vectorP2P3.x ** 2) + (vectorP2P3.y ** 2));
    
        // Calculate the angle in radians
        const angleRadians = Math.acos(dotProduct / (magnitudeP2P1 * magnitudeP2P3));
    
        // Convert the angle to degrees
        const angleDegrees = angleRadians * (180 / Math.PI);
    
        return angleDegrees;
    }
    

    updateRArmAngle() {
        let keypoints = this.kpsList[this.kpsList.length - 1];

        // console.log('keypoints keypoints: ' + keypoints);
        // MPII Keypoints Index (with x, y pairs)
        const rightWristIndex = 6 * 2; // (x, y) pair, so we multiply by 2
        const rightElbowIndex = 8 * 2;
        const rightShoulderIndex = 10 * 2;

        // Extract the relevant points from the keypoints array
        const rightWrist = [keypoints[rightWristIndex], keypoints[rightWristIndex + 1]];
        const rightElbow = [keypoints[rightElbowIndex], keypoints[rightElbowIndex + 1]];
        const rightShoulder = [keypoints[rightShoulderIndex], keypoints[rightShoulderIndex + 1]];
        
        const rightArmAngle = this.calculateAngle(rightWrist, rightElbow, rightShoulder);

        this.setRArmAngle(rightArmAngle);
    }

    updateLArmAngle() {
        let keypoints = this.kpsList[this.kpsList.length - 1];
                // MPII Keypoints Index (with x, y pairs)
        const leftShoulderIndex = 11 * 2; // (x, y) pair, so we multiply by 2
        const leftElbowIndex = 9 * 2;
        const leftWristIndex = 7 * 2;

        // Extract the relevant points from the keypoints array
        const leftShoulder = [keypoints[leftShoulderIndex], keypoints[leftShoulderIndex + 1]];
        const leftElbow = [keypoints[leftElbowIndex], keypoints[leftElbowIndex + 1]];
        const leftWrist = [keypoints[leftWristIndex], keypoints[leftWristIndex + 1]];

        const leftArmAngle = this.calculateAngle(leftWrist, leftElbow, leftShoulder);

        this.setLArmAngle(leftArmAngle);
    }

    updateLLegAngle() {
        let keypoints = this.kpsList[this.kpsList.length - 1];
                // MPII Keypoints Index (with x, y pairs)
        const leftHipIndex = 5 * 2;    // (x, y) pair, so we multiply by 2
        const leftKneeIndex = 3 * 2;
        const leftAnkleIndex = 1 * 2;

        // Extract the relevant points from the keypoints array
        const leftHip = [keypoints[leftHipIndex], keypoints[leftHipIndex + 1]];
        const leftKnee = [keypoints[leftKneeIndex], keypoints[leftKneeIndex + 1]];
        const leftAnkle = [keypoints[leftAnkleIndex], keypoints[leftAnkleIndex + 1]];

        const leftLegAngle = this.calculateAngle(leftHip, leftKnee, leftAnkle);

        this.setLLegAngle(leftLegAngle);

    }

    updateRLegAngle() {
        let keypoints = this.kpsList[this.kpsList.length - 1];

        // MPII Keypoints Index (with x, y pairs)
        const rightHipIndex = 4 * 2;    // (x, y) pair, so we multiply by 2
        const rightKneeIndex = 2 * 2;
        const rightAnkleIndex = 0 * 2;
        
        // Extract the relevant points from the keypoints array
        const rightHip = [keypoints[rightHipIndex], keypoints[rightHipIndex + 1]];
        const rightKnee = [keypoints[rightKneeIndex], keypoints[rightKneeIndex + 1]];
        const rightAnkle = [keypoints[rightAnkleIndex], keypoints[rightAnkleIndex + 1]];
        
        const rightLegAngle = this.calculateAngle(rightHip, rightKnee, rightAnkle);
        
        this.setRLegAngle(rightLegAngle);
    }

    calculateWristSpeed() {
        if (this.kpsList.length < 5) {
            return 0; // Not enough data to calculate speed
        }

        // Get the latest two keypoints
        const latest = this.kpsList[this.kpsList.length - 1];
        const previous = this.kpsList[this.kpsList.length - 3];

        // Wrist indices for left and right wrists
        const rightWristIndex = 6 * 2;
        const leftWristIndex = 7 * 2;

        // Calculate the distance moved by the right wrist
        const rightWristDistance = this.calculateDistance(
            latest[rightWristIndex], latest[rightWristIndex + 1],
            previous[rightWristIndex], previous[rightWristIndex + 1]
        );

        // Calculate the distance moved by the left wrist
        const leftWristDistance = this.calculateDistance(
            latest[leftWristIndex], latest[leftWristIndex + 1],
            previous[leftWristIndex], previous[leftWristIndex + 1]
        );

        // Calculate the time difference between frames (in seconds)
        const timeDifference = 1;

        // Calculate speed (distance per second)
        const rightWristSpeed = rightWristDistance / timeDifference * 1000;
        const leftWristSpeed = leftWristDistance / timeDifference * 1000;

        this.setRWristSpeed(rightWristSpeed);
        this.setLWristSpeed(leftWristSpeed);

        this.setActivityScore((rightWristSpeed + leftWristSpeed) / 2);

        // console.log("Right Wrist Speed: " + rightWristSpeed);
        
        // console.log("Left Wrist Speed: " + leftWristSpeed);
    

    }

    calculateDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    }

    updateAll() {
        this.updateLArmAngle();
        this.updateRArmAngle();
        this.updateLLegAngle();
        this.updateRLegAngle();
        this.calculateWristSpeed();
    }
    
}

export default Score;