import './App.css';
import './Basic.css';
import _ from 'lodash';
import { Button, Container, FormControl, InputLabel, Select, MenuItem, Paper, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import {chartInitData, chartOptions} from "./chartSettings";
import ScoreChart from './ScoreChart';
import {initModel, tutorPoseRun, cameraPoseRun, studentPoseRun, peerPoseRun, setUpdateScore} from './poseRuntime';
import Camera from "@mediapipe/camera_utils";
import Score from './score';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0971f1',
      darker: '#053e85',
    },
    neutral: {
      main: '#dddddd',
      contrastText: '#fff',
    },
  },
});

let camera = null;

let initRun = false;

function App() {
  const [mode, setMode] = useState(0);
  const [showTutorKp, setShowTutorKp] = useState(true);
  const [chartData, setChartData] = useState(chartInitData);
  const [lwristSpeed, setLWristSpeed] = useState(0);
  const [rwristSpeed, setRWristSpeed] = useState(0);
  const [larmAngle, setLArmAngle] = useState(0);
  const [rarmAngle, setRArmAngle] = useState(0);
  const [llegAngle, setLLegAngle] = useState(0);
  const [rlegAngle, setRLegAngle] = useState(0);
  const [activityScore, setActivityScore] = useState(0);
  const [scoreIns, setScoreIns] = useState(null);

  // const scoreIns = new Score(setLWristSpeed,  setRWristSpeed, setLArmAngle, setRArmAngle, setLLegAngle, setRLegAngle, setActivityScore);

  useEffect(() => {
    if (!scoreIns) {
      const scoreIns = new Score(setLWristSpeed,  setRWristSpeed, setLArmAngle, setRArmAngle, setLLegAngle, setRLegAngle, setActivityScore);
      setScoreIns(scoreIns);
      setUpdateScore(setChartData, scoreIns);
    }
  })


  const kp3DCanvas = document.getElementsByClassName("square-box")[0];
  const tutorVidCanvas = document.getElementsByClassName("second-container")[0];
  const videoOne = document.getElementsByClassName("input_video")[0];
  const videoTwo = document.getElementsByClassName("input_video2")[0];

  const postInitModel = (m) => {
    if (m === 0) {
      camera = new Camera.Camera(videoOne, {
        onFrame: () => {cameraPoseRun()},
        width: 1280,
        height: 720
      })
    }
  }

  useEffect(() => {
    if (!initRun) {
      console.log('init');
      initModel(0);
      postInitModel(0);
      initRun = true;
    }
  })

  const handleModeChange = async (event) => {
    if (camera) {camera.stop();}
    videoTwo.pause();
    videoOne.pause();

    const newMode = event.target.value;
    setMode(newMode);
    if (newMode == 2) {
      kp3DCanvas.style.display = "none"
      tutorVidCanvas.style.display = "none"
    } else {
      kp3DCanvas.style.display = ""
      tutorVidCanvas.style.display = ""
    }
    initModel(newMode);
    postInitModel(newMode);
  }
  const handleSelectTutorVid = async () => {
    if (mode === 0) {
      console.log(0);
      const inputElement = document.getElementsByClassName("tutor-video-input-mode0")[0];
      videoTwo.src = URL.createObjectURL(inputElement.files[0]);
    } else {
      console.log(1);
      const inputElement = document.getElementsByClassName("tutor-video-input-mode1")[0];
      videoTwo.src = URL.createObjectURL(inputElement.files[0]);
    }
    videoTwo.requestVideoFrameCallback(tutorPoseRun);
    
  }
  const handleSelectStudentVid = async () => {
    const inputElement = document.getElementsByClassName("student-video-input")[0];
    videoOne.src = URL.createObjectURL(inputElement.files[0]);
    videoOne.requestVideoFrameCallback(studentPoseRun);
  }
  const handleSelectPeerVid = async () => {
    const inputElement = document.getElementsByClassName("peer-video-input")[0];
    videoOne.src = URL.createObjectURL(inputElement.files[0]);
    videoOne.requestVideoFrameCallback(peerPoseRun);
  }

  const startTrain = async () => {
    if (mode === 0) {
      camera.start();
      videoTwo.play();
    } else if (mode === 1) {
      videoOne.play();
      videoTwo.play();
    } else if (mode === 2) {
      videoOne.play();
    }
  }

  return (
    <Container sx={{ width: "400px", marginLeft: "20px", marginTop: "20px" }}>
      <Paper elevation={2} sx={{ width: "400px", padding: "10px", backgroundColor: "#99999970" }}>
        <ThemeProvider theme={darkTheme}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                fullWidth
                color="white"
                align="center"
                sx={{fontWeight: "bold"}}
              >
                {"AI Hip-hop \nBy Nick"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl color="neutral" fullWidth>
                <InputLabel id="demo-simple-select-label">mode</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={mode}
                  label="mode"
                  onChange={handleModeChange}
                >
                  <MenuItem value={0}>Solo Sync</MenuItem>
                  <MenuItem value={1}>Video Pair Sync</MenuItem>
                  <MenuItem value={2}>Peer Sync</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} display={mode === 1? "" : "none" } >
              <Button
                color="neutral"
                variant="outlined"
                fullWidth
                component="label">
                Select Tutor Video
                <input
                  className="tutor-video-input-mode1"
                  onChange={handleSelectTutorVid}
                  type="file"
                  hidden
                  accept="video/*"
                />
              </Button>
            </Grid>
            <Grid item xs={6} display={mode === 1? "" : "none" }>
              <Button
                fullWidth
                color="neutral"
                variant="outlined"
                component="label">
                Select Student Video
                <input
                  type="file"
                  className="student-video-input"
                  hidden
                  accept="video/*"
                  onChange={handleSelectStudentVid}
                />
              </Button>
            </Grid>
            <Grid item xs={12} display={mode === 0? "" : "none" } >
              <Button
                color="neutral"
                variant="outlined"
                fullWidth
                component="label">
                Select Tutor Video
                <input
                  className="tutor-video-input-mode0"
                  onChange={handleSelectTutorVid}
                  type="file"
                  hidden
                  accept="video/*"
                />
              </Button>
            </Grid>
            <Grid item xs={12} display={mode === 2? "" : "none" } >
              <Button
                color="neutral"
                variant="outlined"
                fullWidth
                component="label">
                Select Peer Video
                <input
                  className="peer-video-input"
                  onChange={handleSelectPeerVid}
                  type="file"
                  hidden
                  accept="video/*"
                />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                onClick={startTrain}
                color="neutral"
                variant="outlined"
                component="label">
                Start Training
              </Button>
            </Grid>
            <Grid item xs={12}>
              <ScoreChart chartOptions={chartOptions} chartData={chartData} />
            </Grid>
            {/* add some dance metrics here, with name and score, some is speed, some is angle, some is activity score */}
            <Grid item xs={12}>
              <Typography
                fullWidth
                color="white"
                align="center"
                sx={{fontWeight: "bold"}}
              >
                {"Dance Metrics"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {/* show a list of scores, with 2 decimal */}
              <Typography
                fullWidth
                color="white"
                align="center"
              >
                LWrist Speed: {lwristSpeed.toFixed(2)}
              </Typography>

            </Grid>
            <Grid item xs={6}>
              {/* show a list of scores */}
              <Typography
                fullWidth
                color="white"
                align="center"
              >
                RWrist Speed: {rwristSpeed.toFixed(2)}
              </Typography>

            </Grid>
            <Grid item xs={6}>
              {/* show a list of scores and degree symbol for angle, and keep only 2 decimal */}
              <Typography
                fullWidth
                color="white"
                align="center"
              >
                LARM Angle: {larmAngle.toFixed(2)}°
              </Typography>

            </Grid>
            <Grid item xs={6}>
              {/* show a list of scores */}
              <Typography
                fullWidth
                color="white"
                align="center"
              >
                RARM Angle: {rarmAngle.toFixed(2)}°
              </Typography>

            </Grid>

            <Grid item xs={6}>
              {/* show a list of scores */}
              <Typography
                fullWidth
                color="white"
                align="center"
              >
                LLEG Angle: {llegAngle.toFixed(2)}°
              </Typography>

            </Grid>
            <Grid item xs={6}>
              {/* show a list of scores */}
              <Typography
                fullWidth
                color="white"
                align="center"
              >
                RLEG Angle: {rlegAngle.toFixed(2)}°
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {/* show a list of scores */}
              <Typography
                fullWidth
                color="white"
                align="center"
                sx={{fontWeight: "bold"}}
              >
                Activity Score: {activityScore.toFixed(2)}
              </Typography>
            </Grid>
            
          </Grid>
        </ThemeProvider>
      </Paper>
    </Container>

  );
}

export default App;
