import drawingUtils from "@mediapipe/drawing_utils";
import mpPose from "@mediapipe/pose";

const LandmarkGrid = window.LandmarkGrid;
const landmarkContainer =
    document.getElementsByClassName('landmark-grid-container')[0];
const grid = new LandmarkGrid(landmarkContainer, {
  connectionColor: 0xCCCCCC,
  definedColors:
      [{name: 'LEFT', value: 0xffa500}, {name: 'RIGHT', value: 0x00ffff}],
  range: 2,
  fitToGrid: true,
  labelSuffix: 'm',
  landmarkSize: 2,
  numCellsPerAxis: 4,
  showHidden: false,
  centered: true,
});

const kpConfMin = 0.65;
const multiKpConfMin = 0.25;
let MoveNetLeft = {}
let MoveNetRight = {}
let MoveNetNetraul= {}
let MoveNetConns = []
export function setMoveNetRight(r) {
  MoveNetRight = r;
}
export function setMoveNetLeft(l) {
  MoveNetLeft = l;
}
export function setMoveNetNetraul(n) {
  MoveNetNetraul = n;
}
export function setMoveNetConns(conns) {
  MoveNetConns = conns;
}

const tutorVideo = document.getElementsByClassName('input_video2')[0];
const studentVideo = document.getElementsByClassName('input_video')[0];
const canvasElement =
    document.getElementsByClassName('output_canvas')[0];
const canvasTutorElement =
    document.getElementsByClassName('output_canvas2')[0];
const canvasCtx = canvasElement.getContext('2d');
const canvasTutorCtx = canvasTutorElement.getContext('2d');

export function onResultsTutor(results) {
  // Draw the overlays.
  canvasTutorCtx.save();
  canvasTutorCtx.clearRect(0, 0, canvasTutorElement.width, canvasTutorElement.height);

  canvasTutorCtx.drawImage(
    results.image, 0, 0, canvasTutorElement.width, canvasTutorElement.height);

  if (results.poseLandmarks) {
    drawingUtils.drawConnectors(
        canvasTutorCtx, results.poseLandmarks, mpPose.POSE_CONNECTIONS,
        {visibilityMin: kpConfMin, color: 'white'});
    drawingUtils.drawLandmarks(
        canvasTutorCtx,
        Object.values(mpPose.POSE_LANDMARKS_LEFT)
            .map(index => results.poseLandmarks[index]),
        {visibilityMin: kpConfMin, color: 'white', fillColor: 'rgb(255,138,0)'});
    drawingUtils.drawLandmarks(
        canvasTutorCtx,
        Object.values(mpPose.POSE_LANDMARKS_RIGHT)
            .map(index => results.poseLandmarks[index]),
        {visibilityMin: kpConfMin, color: 'white', fillColor: 'rgb(0,217,231)'});
    drawingUtils.drawLandmarks(
        canvasTutorCtx,
        Object.values(mpPose.POSE_LANDMARKS_NEUTRAL)
            .map(index => results.poseLandmarks[index]),
        {visibilityMin: kpConfMin, color: 'white', fillColor: 'white'});
  }
  canvasTutorCtx.restore();
}

export function onResults(results){

  const aspect = studentVideo.videoHeight / studentVideo.videoWidth;
  let width, height;
  if (window.innerWidth > window.innerHeight) {
    height = window.innerHeight;
    width = height / aspect;
  } else {
    width = window.innerWidth;
    height = width * aspect;
  }
  canvasElement.width = width;
  canvasElement.height = height;

  // Draw the overlays.
  canvasCtx.save();
  canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

  canvasCtx.drawImage(
      results.image, 0, 0, canvasElement.width, canvasElement.height);

  if (results.poseLandmarks) {
    drawingUtils.drawConnectors(
        canvasCtx, results.poseLandmarks, mpPose.POSE_CONNECTIONS,
        {visibilityMin: kpConfMin, color: 'white'});
    drawingUtils.drawLandmarks(
        canvasCtx,
        Object.values(mpPose.POSE_LANDMARKS_LEFT)
            .map(index => results.poseLandmarks[index]),
        {visibilityMin: kpConfMin, color: 'white', fillColor: 'rgb(255,138,0)'});
    drawingUtils.drawLandmarks(
        canvasCtx,
        Object.values(mpPose.POSE_LANDMARKS_RIGHT)
            .map(index => results.poseLandmarks[index]),
        {visibilityMin: kpConfMin, color: 'white', fillColor: 'rgb(0,217,231)'});
    drawingUtils.drawLandmarks(
        canvasCtx,
        Object.values(mpPose.POSE_LANDMARKS_NEUTRAL)
            .map(index => results.poseLandmarks[index]),
        {visibilityMin: kpConfMin, color: 'white', fillColor: 'white'});
  }
  canvasCtx.restore();

  if (results.poseWorldLandmarks) {
    console.log(results.poseWorldLandmarks);
    grid.updateLandmarks(results.poseWorldLandmarks, mpPose.POSE_CONNECTIONS, [
      {list: Object.values(mpPose.POSE_LANDMARKS_LEFT), color: 'LEFT'},
      {list: Object.values(mpPose.POSE_LANDMARKS_RIGHT), color: 'RIGHT'},
    ]);
  } else {
    grid.updateLandmarks([]);
  }
}

export function onResultsMultipose(results){

  const aspect = studentVideo.videoHeight / studentVideo.videoWidth;
  let width, height;
  if (window.innerWidth > window.innerHeight) {
    height = window.innerHeight;
    width = height / aspect;
  } else {
    width = window.innerWidth;
    height = width * aspect;
  }
  canvasElement.width = width;
  canvasElement.height = height;

  // Draw the overlays.
  canvasCtx.save();
  canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

  canvasCtx.drawImage(
      results.image, 0, 0, canvasElement.width, canvasElement.height);

  for (let i=0; i < results.poses.length; i++) {
    const poseLandmarks = results.poses[i].poseLandmarks;
  if (poseLandmarks) {
    console.log(poseLandmarks);
    //mpPose2MPII(poseLandmarks);
    drawingUtils.drawConnectors(
        canvasCtx, poseLandmarks, MoveNetConns,
        {visibilityMin: multiKpConfMin, color: 'white'});
    drawingUtils.drawLandmarks(
        canvasCtx,
        Object.values(MoveNetLeft)
            .map(index => poseLandmarks[index]),
        {visibilityMin: multiKpConfMin, color: 'white', fillColor: 'rgb(255,138,0)'});
    drawingUtils.drawLandmarks(
        canvasCtx,
        Object.values(MoveNetRight)
            .map(index => poseLandmarks[index]),
        {visibilityMin: multiKpConfMin, color: 'white', fillColor: 'rgb(0,217,231)'});
    drawingUtils.drawLandmarks(
        canvasCtx,
        Object.values(MoveNetNetraul)
            .map(index => poseLandmarks[index]),
        {visibilityMin: multiKpConfMin, color: 'white', fillColor: 'white'});
  }

}
  canvasCtx.restore();
}