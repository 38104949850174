import '@tensorflow/tfjs-backend-webgl';
import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
import * as tf from '@tensorflow/tfjs-core';
import * as posedetection from "@tensorflow-models/pose-detection";
import * as mpPose from '@mediapipe/pose';
import { onResultsTutor, onResults, onResultsMultipose, setMoveNetConns, setMoveNetNetraul, setMoveNetLeft, setMoveNetRight} from './display';
import { Mpii2mpPoseIdMap, convertPoseAndNormalize, Mpii2MoveNetIdMap, runSync } from './similarity';
import _ from 'lodash';
import {chartInitData} from './chartSettings';

tfjsWasm.setWasmPaths(
  `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/`);

const backendMap = ["wasm", "wasm", "wasm"];
const runtimeMap = ["mediapipe", "mediapipe", null];

async function resetBackend(backendName) {
  const ENGINE = tf.engine();
  if (!(backendName in ENGINE.registryFactory)) {
    throw new Error(`${backendName} backend is not registed.`);
  }

  if (backendName in ENGINE.registry) {
    const backendFactory = tf.findBackendFactory(backendName);
    tf.removeBackend(backendName);
    tf.registerBackend(backendName, backendFactory);
  }

  await tf.setBackend(backendName);
}

let updateScoreChart = null;
let metricScoreIns = null;
const chartData = chartInitData;

export function setUpdateScore(f, ins) {
  updateScoreChart = f;
  metricScoreIns = ins;
}

function updateScore(score) {
  const maxLength = 50;
  const dps = chartData.datasets[0].data;
  dps.push(score);
  if (dps.length > maxLength) {
    dps.shift();
  }
  chartData.datasets[0].data = dps
  const newData = _.cloneDeep(chartData);
  
  console.log("update score");
  console.log(newData);
  updateScoreChart(newData);

  metricScoreIns.updateAll();
}


export let poseOne = null;
export let poseTwo = null;

const tutorVideo = document.getElementsByClassName('input_video2')[0];
const studentVideo = document.getElementsByClassName('input_video')[0];
console.log(tutorVideo);
console.log(studentVideo);

export const initModel = async (mode) => {
  if (poseOne) {
    console.log('free pose one.')
    poseOne.dispose();
    poseOne = null;
  }
  if (poseTwo) {
    console.log('free pose two.')
    poseTwo.dispose();
    poseTwo = null;
  }

  const backend = backendMap[mode];
  const runtime = runtimeMap[mode];

  //if (mode === 2) { await resetBackend(backend); }

  if (mode === 2) {
    poseOne = await posedetection.createDetector(posedetection.SupportedModels.MoveNet, {
      modelType: posedetection.movenet.modelType.MULTIPOSE_LIGHTNING,
      enableTracking: true
    });
    setMoveNetConns(posedetection.util.getAdjacentPairs(posedetection.SupportedModels.MoveNet));
    const kps = posedetection.util.getKeypointIndexBySide(posedetection.SupportedModels.MoveNet);
    setMoveNetLeft(kps.left);
    setMoveNetRight(kps.right);
    setMoveNetNetraul(kps.middle);

  } else {
    console.log("create pose 1");
    poseOne = await posedetection.createDetector(posedetection.SupportedModels.BlazePose,
      {
        runtime,
        modelType: 'full',
        solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`
      });
    console.log("create pose 2");
    poseTwo = await posedetection.createDetector(posedetection.SupportedModels.BlazePose,
      {
        runtime,
        modelType: 'full',
        solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`
      });
  }

  console.log(poseOne);
  console.log(poseTwo);

}

const defaultFlags = {
  WEBGL_VERSION: 2,
  WASM_HAS_SIMD_SUPPORT: true,
  WASM_HAS_MULTITHREAD_SUPPORT: true,
  WEBGL_CPU_FORWARD: true,
  WEBGL_PACK: true,
  WEBGL_FORCE_F16_TEXTURES: false,
  WEBGL_RENDER_FLOAT32_CAPABLE: true,
  WEBGL_FLUSH_THRESHOLD: -1,
  CHECK_COMPUTATION_FOR_ERRORS: true,
}

export const setTFFlags = () => {
  tf.env().setFlags(defaultFlags)
}

let tutorPose = null;

export const tutorPoseRun = async () => {
  if (poseTwo) {
    const result = (await poseTwo.estimatePoses(tutorVideo))[0];
    if (result) {
      result.image = tutorVideo;
      result.poseLandmarks = result.keypoints;
      for (let i = 0; i < result.poseLandmarks.length; ++i) {
        result.poseLandmarks[i].x = result.poseLandmarks[i].x / tutorVideo.videoWidth;
        result.poseLandmarks[i].y = result.poseLandmarks[i].y / tutorVideo.videoHeight;
        result.poseLandmarks[i].visibility = result.poseLandmarks[i].score;
      }
      tutorPose = convertPoseAndNormalize(_.cloneDeep(result.poseLandmarks), Mpii2mpPoseIdMap);
      onResultsTutor(result);
    }
  }
  tutorVideo.requestVideoFrameCallback(tutorPoseRun);
}

let scoreHist = [];

function avgScore(score) {
  scoreHist.push(score);
  if (scoreHist.length > 10) {
    scoreHist.shift();
  }
  let sum = 0;
  for (let i = 0; i < scoreHist.length; ++i) {
    sum += scoreHist[i]
  }
  return sum / scoreHist.length;
}

const studentScore = async (mpPose) => {
  if (!mpPose) {updateScore(avgScore(0)); return;}
  const tutorPoseClone = _.cloneDeep(tutorPose);
  const studentPose = convertPoseAndNormalize(_.cloneDeep(mpPose), Mpii2mpPoseIdMap);
  const dist = await runSync(studentPose, tutorPoseClone);

  metricScoreIns.updateKps(studentPose);

  const ratio = 1.4;
  const div = 1.2;
  const newScore = Math.exp(-Math.pow(dist * ratio,2)/div) * 100
  
  console.log(dist);
  console.log(newScore);

  updateScore(avgScore(newScore));
}

const peerScore = async (pose1, pose2) => {
  if (!pose1) {updateScore(avgScore(0)); return;}
  const pose1Clone= _.cloneDeep(pose1);
  const pose2Clone= _.cloneDeep(pose2);
  const pose1mpii = convertPoseAndNormalize(_.cloneDeep(pose1), Mpii2MoveNetIdMap);
  const pose2mpii = convertPoseAndNormalize(_.cloneDeep(pose2), Mpii2MoveNetIdMap);
  const dist = await runSync(pose1mpii, pose2mpii);

  metricScoreIns.updateKps(pose1mpii);

  const ratio = 1.4;
  const div = 1.2;
  const newScore = Math.exp(-Math.pow(dist * ratio,2)/div) * 100
  
  console.log(dist);
  console.log(newScore);

  updateScore(avgScore(newScore));
}

export const cameraPoseRun = async () => {
  if (poseOne) {
    let pose = (await poseOne.estimatePoses(studentVideo))[0];
    if (pose) {
      pose.poseLandmarks = pose.keypoints;
      pose.poseWorldLandmarks = pose.keypoints3D;
      for (let i = 0; i < pose.poseLandmarks.length; ++i) {
        pose.poseLandmarks[i].x = pose.poseLandmarks[i].x / studentVideo.videoWidth;
        pose.poseLandmarks[i].y = pose.poseLandmarks[i].y / studentVideo.videoHeight;
        pose.poseLandmarks[i].visibility = pose.poseLandmarks[i].score;
        pose.poseWorldLandmarks[i].visibility = pose.poseWorldLandmarks[i].score;
      }
      studentScore(pose.poseLandmarks);
    } else {
      studentScore(null);
      pose = {}
    }
    pose.image = studentVideo;
    onResults(pose);
  }
}

export const studentPoseRun = async () => {
  if (poseOne) {
    let pose = (await poseOne.estimatePoses(studentVideo))[0];
    if (pose) {
      pose.poseLandmarks = pose.keypoints;
      pose.poseWorldLandmarks = pose.keypoints3D;
      for (let i = 0; i < pose.poseLandmarks.length; ++i) {
        pose.poseLandmarks[i].x = pose.poseLandmarks[i].x / studentVideo.videoWidth;
        pose.poseLandmarks[i].y = pose.poseLandmarks[i].y / studentVideo.videoHeight;
        pose.poseLandmarks[i].visibility = pose.poseLandmarks[i].score;
        pose.poseWorldLandmarks[i].visibility = pose.poseWorldLandmarks[i].score;
      }
      studentScore(pose.poseLandmarks);
    } else {
      studentScore(null);
      pose = {};
    }
    pose.image = studentVideo;
    onResults(pose);
  }
  studentVideo.requestVideoFrameCallback(studentPoseRun);
}

export const peerPoseRun = async () => {
  if (poseOne) {
    let result = await poseOne.estimatePoses(studentVideo);
    const poses = []
    if (result) {
      for (let i = 0; i < result.length; ++i) {
        const pose = result[i];
        pose.poseLandmarks = pose.keypoints;
        pose.poseWorldLandmarks = pose.keypoints3D;
        for (let i = 0; i < pose.poseLandmarks.length; ++i) {
          pose.poseLandmarks[i].x = pose.poseLandmarks[i].x / studentVideo.videoWidth;
          pose.poseLandmarks[i].y = pose.poseLandmarks[i].y / studentVideo.videoHeight;
          pose.poseLandmarks[i].visibility = pose.poseLandmarks[i].score;
        }
        poses.push(pose);
      }
      if (poses.length >= 2) {
        peerScore(poses[0].poseLandmarks, poses[1].poseLandmarks);
      } else {
        peerScore(null, null);
      }
    }
    onResultsMultipose({ image: studentVideo, poses });

  }
  studentVideo.requestVideoFrameCallback(peerPoseRun);
}