const labels = [];
for (let i = 0; i < 50; ++i) { labels.push(i); }
export const chartInitData = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    }
  ],
};

export const chartOptions = {
  animation: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      color: "#dddddd",
      display: true,
      text: 'Synchronization Score',
    },
  },
  scales: {
    xAxis: {
      display: false,
    },
    yAxis: {
      grid: {
        borderColor: "#555555",
        color: "#555555"
      },
      ticks: {
        color: "#dddddd",
      },
      // The axis for this scale is determined from the first letter of the id as `'x'`
      // It is recommended to specify `position` and / or `axis` explicitly.
      max: 100,
      min: 0,
    }
  }
}